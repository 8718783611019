import queryBuilder from "utilities/queryBuilder";
import { GetUserListQueryParams } from "./types";
import { createQuery } from "api/createEndpoint";
import { getCasaiamRolesButReporter } from "utilities/type-helpers/userRoles";
import { TableListQuery } from "hooks/table-list/useTableListQuery";

export const buildUserListQuery = ({
  query,
  output = "",
}: {
  query: TableListQuery;
  output?: string;
}) => {
  const builtQuery = queryBuilder.buildToUrl(
    query.orderBy,
    [
      ...query.filter,
      {
        where: "and",
        field: "status",
        type: "neq",
        value: "ignore",
      },
      // hide casasoft users from customers
      {
        where: "and",
        field: "username",
        type: "notlike",
        value: "%@casasoft.%",
      },
    ],
    {
      context: "user-list",
      pagesize: query.pageSize,
      page: query.page,
    }
  );

  const roles = getCasaiamRolesButReporter().join(",");

  //Used only in case the user clicks download button so the API will return data in text/<output> format
  const outputParam = output ? `&output=${output}` : "";

  return {
    url: `v2/user?roles=${roles}&${builtQuery}${outputParam}`,
    method: "GET",
  };
};

const query = createQuery<GetUserListQueryParams>(buildUserListQuery);

export default query;
